import { simplePoint } from '@kritik/utils/format';
import { isMultiLevelRubric } from '@kritik/utils/rubric';
import classNames from 'classnames';
import ModeLabel from 'components/layout/ModeLabel';
import Spinner from 'components/Loaders/Spinner';
import Checkbox from 'components/Rubric/Component/Input/Checkbox';
import NumericEditor from 'components/Rubric/Component/Input/Numeric';
import Weight from 'components/Rubric/Component/Input/Weight';
import CheckboxMenu from 'components/Rubric/Component/OverlayOptions/CheckboxMenu';
import ModifyRubricButton from 'components/Rubric/Component/OverlayOptions/EdgeButtons';
import RowSelectMenu from 'components/Rubric/Component/OverlayOptions/RowSelection';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';
import { Rubric as RubricDocument } from 'old-common/types.generated';
import React, { lazy, Suspense } from 'react';
const LazyGrid = lazy(() => import('./InternalAgGridReact'));

const MAX_CRITERION_LENGTH = 200;

const TOP_ROW_HEIGHT = 33;

type OwnState = any;

type State = OwnState & typeof Rubric.defaultProps;

class Rubric extends React.Component<{ rubric: RubricDocument }, State> {
  static defaultProps = {
    isEditable: false,
    className: '',
  };

  columnApi: any;
  gridApi: any;

  constructor(props: any) {
    super(props);
    this.state = {
      dragStartIndex: 0,
      rowMenuTop: 0,
      rowMenuHeight: 0,
      rightClickX: 0,
      rightClickY: 0,
      rightClickRowIndex: null,
    };
  }

  componentDidMount() {
    this.refreshGrid();
    if (this.gridApi) {
      if ((this.props as any).printFriendly) {
        this.gridApi.setDomLayout('print');
      } else {
        this.gridApi.setDomLayout('autoHeight');
      }
    }
  }

  componentDidUpdate(prevProps: {}, prevState: State) {
    if ((prevProps as any).rubric != (this.props as any).rubric) {
      this.refreshGrid();
    }
    if (this.gridApi) {
      if ((this.props as any).printFriendly) {
        this.gridApi.setDomLayout('print');
      } else {
        this.gridApi.setDomLayout('autoHeight');
      }
    }
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this.onResize, false);
  }

  onResize() {
    if (!this.gridApi) {
      return;
    }

    setTimeout(() => {
      this.gridApi.sizeColumnsToFit();
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.columnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    window.addEventListener('resize', this.onResize, false);
    params.api.sizeColumnsToFit();

    this.refreshGrid();
  }

  setRowButtonCoordinates() {
    if (!this.gridApi) {
      return null;
    }
    const firstRow = this.gridApi.getDisplayedRowAtIndex(0);
    const rowPositionInPx = firstRow.rowTop + firstRow.rowHeight;
    this.setState({
      rowMenuTop: rowPositionInPx + TOP_ROW_HEIGHT - firstRow.rowHeight,
      rowMenuHeight: firstRow.rowHeight,
    });
  }

  setSelectedMenuCoordinates() {
    if (!this.gridApi || !this.columnApi) {
      return null;
    }
    const selectedRows = this.gridApi.getSelectedRows();
    if (selectedRows.length) {
      // get row index to get height and position
      let lastSelectedRow = selectedRows[selectedRows.length - 1];
      lastSelectedRow = this.gridApi.getDisplayedRowAtIndex(lastSelectedRow.index + 1); // includes header

      // get right coordinate in px
      const colList = this.columnApi.getColumnState();
      const lastCol = colList[colList.length - 1];

      this.setState({
        selectedRowRight: lastCol.width + 10,
        selectedRowHeight: lastSelectedRow.rowHeight,
        selectedRowTop: lastSelectedRow.rowTop + TOP_ROW_HEIGHT,
      });
    } else {
      this.setState({
        selectedRowRight: null,
        selectedRowHeight: null,
        selectedRowTop: null,
      });
    }
  }

  getCheckboxColumn() {
    const checkBoxColumn = {
      headerName: '',
      width: 45,
      suppressSizeToFit: true,
      editable: false,
      pinned: 'left',
      field: 'selected',
      cellRenderer: 'checkbox',
      cellClass: 'ag-checkbox-cell',
    };
    return checkBoxColumn;
  }

  handleCheckboxRightClick(e: any) {
    const colName = e.colDef.cellRenderer;
    if (colName === 'checkbox') {
      console.log(e);
      const clickedRow = this.gridApi.getDisplayedRowAtIndex(e.rowIndex);
      console.log(clickedRow);

      this.setState({
        rightClickX: e.event.layerX,
        rightClickY: e.node.rowTop + e.event.layerY,
        rightClickRowIndex: e.rowIndex - 1,
      });
    }
  }

  unsetContextMenuCoordinates() {
    this.setState({
      rightClickX: 0,
      rightClickY: 0,
      rightClickRowIndex: null,
    });
  }

  getCriteriaColumn() {
    const isEditAllowed = (params: any) => {
      if (params.node.rowIndex === 0) {
        return false;
      }
      return (this.props as any).isEditable;
    };
    const criteriaColumn = {
      cellEditorParams: {
        maxLength: MAX_CRITERION_LENGTH,
      },
      headerName: '',
      field: 'name',
      cellStyle: {
        'font-weight': 'bold',
        'white-space': 'normal',
        cursor: (this.props as any).isEditable ? 'pointer' : '',
        overflowWrap: 'break-word',
      },
      suppressSizeToFit: false,
      editable: isEditAllowed,
      rowDrag: isEditAllowed,
      valueSetter: (params: any) => {
        if (params.newValue.length > MAX_CRITERION_LENGTH) {
          return false;
        }
        params.data.name = params.newValue;
        return true;
      },
    };
    return criteriaColumn;
  }

  getLevelColumns() {
    const { rubric } = this.props;
    const levelColumns = rubric.levels.map((_level: any, i: any) => {
      const column = {
        headerName: `Level ${i}`,
        field: `level_${i}`,
        cellClassRules: {
          'rubric__cell--empty': function (params: any) {
            return params.value == null;
          },
        },
      };
      if (i === 0) {
        column.headerName = `Level 0`;
      }
      return column;
    });
    return levelColumns;
  }

  getWeightColDef() {
    const isEditAllowed = (params: any) => {
      if (params.node.rowIndex === 0) {
        return false;
      }
      return (this.props as any).isEditable;
    };
    const weightFormatter = (params: any) => {
      if (params.node.rowIndex === 0) {
        return '';
      }
      return simplePoint(params.value);
    };

    const weightColumn = {
      headerName: 'Weight',
      field: 'weight',
      pinned: 'right',
      cellEditor: 'numericEditor',
      width: 100,
      suppressSizeToFit: true,
      cellRendererParams: { isEditable: isEditAllowed },
      valueFormatter: weightFormatter,
      cellRendererFramework: (params) => {
        if (params.value) {
          return (
            <>
              {params.data.usemodeValue ? (
                <ModeLabel
                  style="normal"
                  title={localize({ message: 'Rubric.Mode.Tooltip.Normal' })}
                  type="information"
                  location="top"
                  testid="rubric-mode-label"
                />
              ) : null}
              <span style={{ float: 'right' }}>{simplePoint(params.value)}</span>
            </>
          );
        } else {
          return null;
        }
      },
    };

    return weightColumn;
  }

  getUseModeColDef() {
    return {
      headerName: 'Use Mode',
      field: 'usemode',
      hide: true,
      editable: false,
    };
  }

  getColumnDefs() {
    let columns = [];
    if ((this.props as any).isEditable) {
      columns.push(this.getCheckboxColumn());
    }
    columns.push(this.getCriteriaColumn());
    columns = columns.concat(this.getLevelColumns());
    columns.push(this.getWeightColDef());
    columns.push(this.getUseModeColDef());
    return columns;
  }

  refreshGrid() {
    if (!this.gridApi) {
      return;
    }
    const { rubric } = this.props;
    const dataRows = rubric.grid.map((row: any, i: any) => {
      const criterion = rubric.criteria[i];

      const rowEntry = {
        index: i,
        name: criterion.name,
        weight: criterion.weight,
        usemode: criterion.useMode,
      };
      rubric.levels.forEach((level: any, j: any) => {
        let cell = null;
        if (j < row.length) {
          cell = row[j];
        }
        rowEntry[`level_${j}`] = cell;
      });
      return rowEntry;
    });

    dataRows.forEach((row: any) => {
      row.usemodeValue = row.usemode;
    });

    const columnRow = {};
    rubric.levels.forEach((level: any, i: any) => {
      columnRow[`level_${i}`] = level;
    });

    const columnDefs = this.getColumnDefs();
    const rowData = [columnRow].concat(dataRows);
    this.gridApi.setColumnDefs(columnDefs);
    this.gridApi.sizeColumnsToFit();

    this.gridApi.setRowData(rowData);
    if ((this.props as any).onSelectRow) {
      (this.props as any).onSelectRow([]);
    }
    this.setRowButtonCoordinates();
  }

  onCellEdited = () => {
    const { levels, criteria } = (this.props as any).rubric;
    const _levels = [...levels];
    const _criteria = [...criteria];
    const grid: any = [];
    this.gridApi.forEachNode((node: any, i: any) => {
      if (i == 0) {
        levels.forEach((_level: any, j: any) => {
          _levels[j] = node.data[`level_${j}`];
        });
      } else {
        const criterion = {
          name: node.data.name,
          //Casting to string to display the weight when it equals 0, otherwise AG-Grid hides this value
          weight: String(node.data.weight),
          useMode: node.data.usemode,
        };
        _criteria[i - 1] = criterion;
        const row: any = [];
        levels.forEach((_level: any, j: any) => {
          const cell = node.data[`level_${j}`];
          if (cell !== null) {
            row.push(cell);
          }
        });
        grid.push(row);
      }
    });
    (this.props as any).onChangeCell({ levels: _levels, criteria: _criteria, grid });
  };

  onSelectionChanged = (e: any) => {
    const selectedRows = this.gridApi.getSelectedRows();
    if ((this.props as any).onSelectRow) {
      (this.props as any).onSelectRow(selectedRows);
    }
    this.setSelectedMenuCoordinates();
  };

  onRowDragEnd = (e: any) => {
    let isRowMoved = true;
    this.gridApi.forEachNode((node: any, i: any) => {
      if (node === e.node && i === this.state.dragStartIndex) {
        isRowMoved = false;
      }
    });
    if (!isRowMoved) {
      return;
    }
    this.onCellEdited();
  };

  onRowDragStart = (e: any) => {
    this.gridApi.forEachNode((node: any, i: any) => {
      if (node === e.node) {
        this.setState({ dragStartIndex: i });
      }
    });
  };

  renderOverlayMenu = () => {
    if (!(this.props as any).isEditable) {
      return null;
    }
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      if (selectedRows.length) {
        return (
          <RowSelectMenu
            rightCoordinate={this.state.selectedRowRight}
            rowHeight={this.state.selectedRowHeight}
            topCoordinate={this.state.selectedRowTop}
            handleAddLevel={(this.props as any).handleAddLevels}
            handleRemoveLevel={(this.props as any).handleRemoveLevels}
            handleRemoveCriteria={(this.props as any).handleRemoveCriteria}
            handleToggleModeScoring={(this.props as any).handleToggleModeScoring}
          />
        );
      }
      if (this.state.rightClickY && this.state.rightClickX) {
        return (
          <CheckboxMenu
            xCoordinate={this.state.rightClickX}
            yCoordinate={this.state.rightClickY}
            clickedRowIndex={this.state.rightClickRowIndex}
            handleAddLevel={(this.props as any).handleAddLevels}
            handleRemoveLevel={(this.props as any).handleRemoveLevels}
            handleRemoveCriteria={(this.props as any).handleRemoveCriteria}
            handleAddCriteria={(this.props as any).handleAddCriteria}
            onClose={this.unsetContextMenuCoordinates.bind(this)}
          />
        );
      }
    }
    return (
      <React.Fragment>
        <div className="rubric__col-btn" data-testid="manage-rubric-criteria-button">
          <ModifyRubricButton
            handleAdd={(this.props as any).handleAddCriteria}
            handleRemove={(this.props as any).handleRemoveCriteria}
            isModifyCriteria
          />
        </div>
        <div
          className="rubric__row-btn"
          style={{
            height: this.state.rowMenuHeight,
            top: this.state.rowMenuTop,
          }}
          data-testid="manage-rubric-level-button"
        >
          <ModifyRubricButton
            handleAdd={(this.props as any).handleAddLevels}
            handleRemove={(this.props as any).handleRemoveLevels}
            isModifyCriteria={false}
          />
        </div>
      </React.Fragment>
    );
  };

  getCellEditorParams() {
    let defRows = '6';
    if ((this.props as any).rubric.grid.length === 1) {
      defRows = '4';
    }
    return { maxLength: '1000', rows: defRows };
  }

  render() {
    const rubricClass = classNames(
      'ag-theme-balham',
      'rubric',
      {
        'rubric--editable': (this.props as any).isEditable,
      },
      (this.props as any).className
    );

    return (
      <div
        className={rubricClass}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        {this.renderOverlayMenu()}
        <Suspense
          fallback={
            <div className="rubric--loading">
              <Spinner size="40px" />
              <TranslatedText i18nKey="Rubric.Loading" />
            </div>
          }
        >
          <LazyGrid
            columnDefs={[]}
            rowData={[]}
            rowSelection={(this.props as any).isEditable ? 'multiple' : ''}
            suppressRowClickSelection
            domLayout="autoHeight"
            onGridReady={this.onGridReady.bind(this)}
            onColumnResized={(params) => {
              params.api.resetRowHeights();
            }}
            suppressRowTransform
            defaultColDef={{
              resizable: true,
              editable: (params) => {
                const blockLevelEditing = isMultiLevelRubric(this.props.rubric);
                // @ts-expect-error TS(2339) FIXME: Property 'isEditable' does not exist on type 'Read... Remove this comment to see the full error message
                if (!this.props.isEditable) {
                  return false;
                }
                if (blockLevelEditing && params.node.rowIndex === 0) {
                  return false;
                }
                return true;
              },
              suppressMovable: true,
              cellEditor: 'agLargeTextCellEditor',
              cellEditorParams: this.getCellEditorParams(),
              cellStyle: (params) => {
                const blockLevelEditing = isMultiLevelRubric(this.props.rubric);
                return {
                  'font-weight': params.node.rowIndex === 0 ? 'bold' : 'initial',
                  'white-space': 'normal',
                  // @ts-expect-error TS(2339) FIXME: Property 'isEditable' does not exist on type 'Read... Remove this comment to see the full error message
                  cursor: this.props.isEditable
                    ? blockLevelEditing && params.node.rowIndex === 0
                      ? 'not-allowed'
                      : 'pointer'
                    : '',
                };
              },
              autoHeight: true,
              wrapText: true,
              width: 150,
            }}
            animateRows
            rowDragManaged
            onRowDragEnd={this.onRowDragEnd}
            onCellClicked={this.unsetContextMenuCoordinates.bind(this)}
            onCellContextMenu={(e) => {
              this.handleCheckboxRightClick(e);
            }}
            onRowDragEnter={this.onRowDragStart}
            onCellEditingStopped={this.onCellEdited}
            frameworkComponents={{
              numericEditor: NumericEditor,
              checkbox: (props: any) => (
                <Checkbox
                  {...props}
                  onSelectionChanged={this.onSelectionChanged}
                  isEditable={(this.props as any).isEditable}
                  testid="checkbox-rubric-edit"
                />
              ),
              weight: (props: any) => <Weight {...props} isEditable={(this.props as any).isEditable} />,
            }}
          />
        </Suspense>
      </div>
    );
  }
}

export default Rubric;
